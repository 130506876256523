import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

import './Loader.scss';

function Loader(props) {
    let mod_class = [
        'Loader',
        props.fullscreen ? 'is-fullscreen' : '',
        props.opaque ? 'is-opaque' : '',
        props.theme ? 'theme-' + props.theme : '',
    ];
    mod_class = mod_class.join(' ');

    return (
        <div className={mod_class}>
            <CircularProgress />
        </div>
    );
}

Loader.propTypes = {};

export default Loader;
