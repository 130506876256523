import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import Loader from '@atoms/Loader/Loader';

interface Props {
    name: string;
    onView: VoidFunction;
}

const LazyloadController: React.FC<Props> = ({ name, onView }) => {
    const [contentRef, contentInView] = useInView({
        trackVisibility: true,
        triggerOnce: true,
        delay: 100,
    });
    const [isShown, setIsShown] = useState(false);

    useEffect(() => {
        if (contentInView && !isShown) {
            setIsShown(true);
            onView();
        }
    }, [contentInView]);

    return <div ref={contentRef}>{isShown ? <></> : <Loader />}</div>;
};

export default LazyloadController;
